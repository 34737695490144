/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

const requiredFields = [
  // step 0
  // nothing to check

  // step 1
  // {
  //   step: 1,
  //   section: "objects",
  //   fieldTitle: "Uploaded files",
  //   check: "length",
  //   message: "No files have been uploaded",
  // },

  // step 2
  {
    step: 2,
    section: "instrument",
    fieldName: "creator",
    fieldTitle: "Author",
    check: "length",
    message: "Step 2: Please select a `Author`.",
  },
  {
    step: 2,
    section: "instrument",
    fieldName: "materialType",
    fieldTitle: "Type of Material",
    check: "length",
    message: "Step 2: Please select a `Type of Material`.",
  },
  {
    step: 2,
    section: "instrument",
    fieldName: "primaryMaterialType",
    fieldTitle: "Choose which label best describes your deposit.",
    check: "length",
    message:
      "Step 2: Please select a `Choose which label best describes your deposit:`.",
  },
  {
    step: 2,
    section: "instrument",
    fieldName: "title",
    fieldTitle: "Material Title or Name",
    check: "length",
    message: "Step 2: Please enter a `Material Title or Name`.",
  },
  {
    step: 2,
    section: "instrument",
    fieldName: "researchArea",
    fieldTitle: "General Research Area",
    check: "length",
    message: "Step 2: Please select a `General Research Area`.",
  },
  {
    step: 2,
    section: "instrument",
    fieldName: "typeOfFile",
    fieldTitle: "Type of File",
    check: "length",
    message: "Step 2: Please select a `Type of File`.",
  },

  // step 3
  {
    step: 3,
    section: "participant",
    fieldName: "type",
    fieldTitle: "Participant Type",
    check: "length",
    message: "Step 3: Please select a `Participant type(s)`.",
  },
  {
    step: 3,
    section: "participant",
    fieldName: "proficiencyOfLearners",
    fieldTitle: "Proficiency of learners/users",
    check: "length",
    message: "Step 3: Please select a `Proficiency of learners`.",
  },

  // step 4
  {
    step: 4,
    section: "publication",
    fieldTitle: "Linked publications",
    check: "length",
    message: "Step 4: Please add one linked publication.",
  },

  // step 5
  // nothing to check

  // step 6
  {
    step: 6,
    section: "settings",
    fieldName: "rights",
    fieldTitle: "Creative Commons Licence Selection",
    check: "length",
    message: "Step 6: Please select a `Creative Commons Licence`.",
  },
  {
    step: 6,
    section: "settings",
    fieldName: "feedback",
    fieldTitle: "Feedback",
    check: "length",
    message: "Step 6: Please select a `Feedback` option.",
  },
  {
    step: 6,
    section: "settings",
    fieldName: "licenceAgreement",
    fieldTitle: "Terms and Conditions Declaration",
    check: "length",
    message: "Step 6: Please accept the Terms and Conditions Declaration.",
  },
];

const requiredFieldPublications = [
  {
    type: "Journal Article",
    fields: [
      {
        step: 4,
        section: "publications",
        type: "journalArticle",
        fieldTitle: "Author",
        fieldName: "author",
        check: "length",
        message:
          "Step 4: Please select at least one author for the journal article.",
      },
      {
        step: 4,
        section: "publications",
        type: "journalArticle",
        fieldTitle: "Title of Article",
        fieldName: "title",
        check: "length",
        message:
          "Step 4: Please enter a title of article for the journal article.",
      },
      {
        step: 4,
        section: "publications",
        type: "journalArticle",
        fieldTitle: "Name of Journal",
        fieldName: "journalName",
        check: "length",
        message:
          "Step 4: Please select name of journal for the journal article.",
      },
      {
        step: 4,
        section: "publications",
        type: "journalArticle",
        fieldTitle: "Publication date",
        fieldName: "publicationDate",
        check: "length",
        message:
          "Step 4: Please select a publication date for the journal article.",
      },
      {
        step: 4,
        section: "publications",
        type: "journalArticle",
        fieldTitle: "Publication date",
        fieldName: "publicationDate",
        check: "value",
        valueNot: "Please choose",
        message:
          "Step 4: Please select a publication date for the chapter reference.",
      },
    ],
  },
  {
    type: "Book",
    fields: [
      {
        step: 4,
        section: "publications",
        type: "book",
        fieldTitle: "Author",
        fieldName: "author",
        check: "length",
        message:
          "Step 4: Please select at least one author for the book reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "book",
        fieldTitle: "Title of Book",
        fieldName: "title",
        check: "length",
        message: "Step 4: Please enter a title of book for the book reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "book",
        fieldTitle: "Publication date",
        fieldName: "publicationDate",
        check: "length",
        message:
          "Step 4: Please select a publication date for the book reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "book",
        fieldTitle: "Publication date",
        fieldName: "publicationDate",
        check: "value",
        valueNot: "Please choose",
        message:
          "Step 4: Please select a publication date for the chapter reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "book",
        fieldTitle: "Publisher",
        fieldName: "publisher",
        check: "length",
        message:
          "Step 4: Please select at least one publisher for the book reference.",
      },
    ],
  },
  {
    type: "Chapter",
    fields: [
      {
        step: 4,
        section: "publications",
        type: "chapter",
        fieldTitle: "Author",
        fieldName: "author",
        check: "length",
        message:
          "Step 4: Please select at least one author for the chapter reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "chapter",
        fieldTitle: "Title of Book",
        fieldName: "bookTitle",
        check: "length",
        message:
          "Step 4: Please enter a title of book for the chapter reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "chapter",
        fieldTitle: "Title of Chapter",
        fieldName: "title",
        check: "length",
        message:
          "Step 4: Please enter a title of chapter for the chapter reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "chapter",
        fieldTitle: "Publication date",
        fieldName: "publicationDate",
        check: "length",
        message:
          "Step 4: Please select a publication date for the chapter reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "chapter",
        fieldTitle: "Publication date",
        fieldName: "publicationDate",
        check: "value",
        valueNot: "Please choose",
        message:
          "Step 4: Please select a publication date for the chapter reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "chapter",
        fieldTitle: "Publisher",
        fieldName: "publisher",
        check: "length",
        message:
          "Step 4: Please select at least one publisher for the chapter reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "chapter",
        fieldTitle: "Editor",
        fieldName: "editor",
        check: "length",
        message:
          "Step 4: Please select at least one editor for the chapter reference.",
      },
    ],
  },
  {
    type: "Proceedings",
    fields: [
      {
        step: 4,
        section: "publications",
        type: "proceedings",
        fieldTitle: "Author",
        fieldName: "author",
        check: "length",
        message:
          "Step 4: Please select at least one author for the proceedings reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "proceedings",
        fieldTitle: "Title of Proceedings",
        fieldName: "title",
        check: "length",
        message:
          "Step 4: Please enter a title of proceedings for the proceedings reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "proceedings",
        fieldTitle: "Publication date",
        fieldName: "publicationDate",
        check: "length",
        message:
          "Step 4: Please select a publication date for the proceedings reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "proceedings",
        fieldTitle: "Publication date",
        fieldName: "publicationDate",
        check: "value",
        valueNot: "Please choose",
        message:
          "Step 4: Please select a publication date for the proceedings reference.",
      },
      // {
      //   step: 4,
      //   section: "publications",
      //   type: "chapter",
      //   fieldTitle: "Publisher",
      //   fieldName: "publisher",
      //   check: "length",
      //   message:
      //     "Step 4: Please select at least one publisher for the proceedings reference.",
      // },
      {
        step: 4,
        section: "publications",
        type: "proceedings",
        fieldTitle: "Editor",
        fieldName: "editor",
        check: "length",
        message:
          "Step 4: Please select at least one editor for the proceedings reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "proceedings",
        fieldTitle: "Conference name",
        fieldName: "conferenceName",
        check: "length",
        message:
          "Step 4: Please select at least one conference name for the proceedings reference.",
      },
    ],
  },
  {
    type: "Thesis",
    fields: [
      {
        step: 4,
        section: "publications",
        type: "thesis",
        fieldTitle: "Author",
        fieldName: "author",
        check: "length",
        message:
          "Step 4: Please select at least one author for the thesis reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "thesis",
        fieldTitle: "Title of Thesis",
        fieldName: "title",
        check: "length",
        message:
          "Step 4: Please enter a title of thesis for the thesis reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "thesis",
        fieldTitle: "Awarding Institution",
        fieldName: "awardingInstitution",
        check: "length",
        message:
          "Step 4: Please select a awarding institution for the thesis reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "thesis",
        fieldTitle: "Date of Award",
        fieldName: "dateOfAward",
        check: "length",
        valueNot: "Please choose",
        message:
          "Step 4: Please select a date of award for the thesis reference.",
      },
      {
        step: 4,
        section: "publications",
        type: "thesis",
        fieldTitle: "Date of Award",
        fieldName: "dateOfAward",
        check: "value",
        valueNot: "Please choose",
        message:
          "Step 4: Please select a date of award for the thesis reference.",
      },
    ],
  },
  {
    type: "oasis-link",
    fields: [
      {
        step: 4,
        section: "publications",
        type: "oasisLink",
        fieldTitle: "OASIS Summary",
        fieldName: "oasisLink",
        check: "length",
        message:
          "Step 4: Please enter a OASIS summary for the OASIS link reference.",
      },
    ],
  },
];

interface checkFieldValueProps {
  requiredField: any;
  fieldValue: any;
}

const checkFieldValue = (props: checkFieldValueProps) => {
  const { requiredField, fieldValue } = props;

  const { check, valueNot } = requiredField;

  // console.log("🚀 ~ checkFieldValue ~ fieldValue:", fieldValue);

  if (check === "length") {
    if (!fieldValue || fieldValue.length < 1) {
      return requiredField;
    }
  } else if (check === "value") {
    if (!fieldValue || fieldValue.value === valueNot) {
      return requiredField;
    }
  } else {
    // other checks...
  }

  return "";
};

interface checkFieldProps {
  submissionState: any;
  requiredField: any;
  fieldValue?: any;
}

const checkField = (props: checkFieldProps) => {
  const { submissionState, requiredField, fieldValue } = props;

  const { section, fieldName, check } = requiredField; // eslint-disable-line prettier/prettier

  if (check === "length") {
    if (fieldValue) {
      if (fieldValue.length < 1) {
        return requiredField;
      }
    } else if (fieldName) {
      if (submissionState[section][fieldName].length < 1) {
        return requiredField;
      }
    } else if (submissionState[section].length < 1) {
      return requiredField;
    }
  } else {
    // other checks...
  }
};

interface validateSubmissionFormProps {
  submissionState: any;
  step?: number;
  allSteps?: boolean;
}

const validateSubmissionForm = async (props: validateSubmissionFormProps) => {
  const { submissionState, step, allSteps } = props;

  const invalidFields = [];

  const fieldsToCheck = allSteps
    ? requiredFields
    : requiredFields.filter((item) => item.step === step);

  for (let index = 0; index < fieldsToCheck.length; index += 1) {
    const requiredField = requiredFields[index];

    const checkResponse = checkField({ submissionState, requiredField });

    if (checkResponse) {
      invalidFields.push(checkResponse);
    }
  }

  // eslint-disable-next-line radix
  if ((step && parseInt(step) === 4) || allSteps) {
    submissionState.publication.map((publicationItem) => {
      // Get all required fields for the selected publication type
      const publicationRequiredFields = requiredFieldPublications.filter(
        (item) => item.type === publicationItem.publicationType.value
      );

      // console.log("🚀 ~ publicationRequiredFields:", publicationRequiredFields);

      if (
        publicationRequiredFields.length > 0 &&
        publicationRequiredFields[0].fields.length > 0
      ) {
        publicationRequiredFields[0].fields.map((requiredField) => {
          // console.log("🚀 ~ requiredField:", requiredField);

          const checkResponse = checkFieldValue({
            requiredField,
            fieldValue: publicationItem[requiredField.fieldName],
          });

          if (checkResponse) {
            invalidFields.push(checkResponse);
          }

          return "";
        });
      }

      return "";
    });
  }

  return invalidFields;
};

export default validateSubmissionForm;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { isEmpty, concat, range } from "lodash";
import InputField from "../fields/inputField";
import SelectField from "../fields/selectField";
import { infoBoxesContent } from "../infoBoxesContent";
import InputWrapper from "../inputWrapper";
import { DictionaryDataState } from "../../../context/DictionaryDataContext";
import LoadingIcon from "../../loadingIcon";
import NewAuthorForm from "../fields/newAuthorForm";
import { PublicationFieldProps } from "../../../types/SubmissionTypes";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";
import NewOtherForm from "../fields/newOtherForm";

const issues = [
  "Please choose",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "Supplement 1",
  "Supplement 2",
  "Supplement 3",
  "Supplement 4",
  "no issue",
];

const thisYear = new Date().getFullYear();

export default function JournalFields(props: PublicationFieldProps) {
  const dictionaryDataState = useContext(DictionaryDataState) || {
    authors: [],
    journals: [],
  };
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const { referenceNumber, acknowledgementNumber, triggerSaveMessageHandler, materialSettings } = props; // eslint-disable-line prettier/prettier

  const [authors, setAuthors] = useState([]);
  const [journals, setJournals] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isEmpty(dictionaryDataState)) {
      const { authors: ddAuthors, journals: ddJournals } = dictionaryDataState;

      setAuthors(ddAuthors);
      setJournals(ddJournals);

      // Set loaded to `true` so that the content is shown to the user
      setLoaded(true);
    }
  }, [dictionaryDataState]);

  // Get the authors from step 2 and save them here
  useEffect(() => {
    if (
      referenceNumber >= 0 &&
      submissionState?.publication[referenceNumber]?.author === undefined
    ) {
      submissionDispatch({
        type: "publicationChangeHandler",
        value: submissionState.instrument.creator,
        fieldName: "author",
        referenceNumber,
      });
    }

    if (
      acknowledgementNumber >= 0 &&
      submissionState?.acknowledgement[acknowledgementNumber]?.publication
        ?.author === undefined
    ) {
      submissionDispatch({
        type: "acknowledgementChangeHandler",
        value: submissionState.instrument.creator,
        fieldName: "author",
        acknowledgementNumber,
      });
    }
  }, []);

  return loaded ? (
    <>
      <InputWrapper
        title="Author (last name, first name or initial)"
        infoBoxContent={infoBoxesContent.publicationTypeAuthorInfo}
        required
      >
        <SelectField
          name="author"
          options={authors}
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />

        <NewAuthorForm
          authors={authors}
          setAuthors={setAuthors}
          fieldName="author"
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          doiField
        />
      </InputWrapper>

      <InputWrapper
        title="Title of Article"
        infoBoxContent={infoBoxesContent.publicationTypeTitleOfArticleInfo}
        required
      >
        <InputField
          name="title"
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          doiField
        />
      </InputWrapper>

      <InputWrapper
        title="Name of Journal"
        infoBoxContent={infoBoxesContent.publicationTypeNameOfJournalInfo}
        required
      >
        <SelectField
          name="journalName"
          options={journals}
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />

        <NewOtherForm
          title="name of journal"
          inputName="journalNameNew"
          fieldName="journalName"
          dictionaryStateName="journals"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          showParent={false}
          options={journals}
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
        />
      </InputWrapper>

      <InputWrapper
        title="Publication date"
        infoBoxContent={infoBoxesContent.publicationTypePublicationDateInfo}
        required
      >
        <SelectField
          name="publicationDate"
          options={concat(
            ["Please choose", "In press"],
            range(thisYear, 1949)
          ).map((item) => {
            return {
              value: item,
              label: item,
            };
          })}
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          isMulti={false}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <div className="pb-3 mb-3 border-b border-b-gray-2">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-4 md:col-span-4">
            <InputWrapper
              title="Volume"
              infoBoxContent={infoBoxesContent.publicationTypeVolumeInfo}
              hideBorder
              hidePadding
              hideMargin
              titlePadding="pb-5"
            >
              <InputField
                name="volume"
                placeholder="Volume"
                referenceNumber={referenceNumber}
                acknowledgementNumber={acknowledgementNumber}
                triggerSaveMessageHandler={triggerSaveMessageHandler}
              />
            </InputWrapper>
          </div>
          <div className="col-span-4 md:col-span-4">
            <InputWrapper
              title="Issue"
              infoBoxContent={infoBoxesContent.publicationTypeIssueInfo}
              hideBorder
              hidePadding
              hideMargin
              titlePadding="pb-5"
            >
              <SelectField
                name="issue"
                options={issues.map((item) => {
                  return { value: item, label: item };
                })}
                referenceNumber={referenceNumber}
                acknowledgementNumber={acknowledgementNumber}
                isMulti={false}
                triggerSaveMessageHandler={triggerSaveMessageHandler}
              />
            </InputWrapper>
          </div>
          <div className="col-span-4 md:col-span-4">
            <InputWrapper
              title="Pages"
              infoBoxContent={infoBoxesContent.publicationTypePagesInfo}
              hideBorder
              hidePadding
              hideMargin
              titlePadding="pb-1"
            >
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-6 md:col-span-6">
                  <div className="text-xs">From:</div>
                  <InputField
                    name="pageFrom"
                    placeholder="From"
                    referenceNumber={referenceNumber}
                    acknowledgementNumber={acknowledgementNumber}
                    triggerSaveMessageHandler={triggerSaveMessageHandler}
                  />
                </div>
                <div className="col-span-6 md:col-span-6">
                  <div className="text-xs">To:</div>
                  <InputField
                    name="pageTo"
                    placeholder="To"
                    referenceNumber={referenceNumber}
                    acknowledgementNumber={acknowledgementNumber}
                    triggerSaveMessageHandler={triggerSaveMessageHandler}
                  />
                </div>
              </div>
            </InputWrapper>
          </div>
        </div>
      </div>

      <InputWrapper
        title="Identifier"
        infoBoxContent={infoBoxesContent.identifierInfo}
        customClasses="addAnotherIdentifierLink"
      >
        <InputField
          name="identifier"
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          placeholder="https://doi.org/"
        />
      </InputWrapper>
    </>
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
}

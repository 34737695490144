import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { graphql, useStaticQuery } from "gatsby";
import SelectField from "../fields/selectField";
import TextareaField from "../fields/textareaField";
import { infoBoxesContent } from "../infoBoxesContent";
import InputWrapper from "../inputWrapper";
import { step7options } from "../../../data/step7options";
import { DictionaryDataState } from "../../../context/DictionaryDataContext";
import { SubmissionState } from "../../../context/SubmissionContext";

interface StepProps {
  triggerSaveMessageHandler: any;
}

export default function Step7(props: StepProps) {
  const dictionaryDataState = useContext(DictionaryDataState) || { rights: [] };
  const submissionState = useContext(SubmissionState) || {
    settings: { rights: { value: "" } },
  };

  const { triggerSaveMessageHandler } = props;
  const { feedback, licenceAgreement } = step7options;

  const [rights, setRights] = useState<any>([]);

  useEffect(() => {
    if (!isEmpty(dictionaryDataState)) {
      const { rights: ddRights } = dictionaryDataState;

      const filteredRights = ddRights
        .filter((item: any) => {
          if (submissionState.settings.rights) {
            if (
              (item.label && item.label.includes("4.0")) ||
              item.id === submissionState.settings.rights.value
            ) {
              return item;
            }
          } else if (item.label && item.label.includes("4.0")) {
            return item;
          } else {
            //
          }
        })
        .map((item: any) => {
          return {
            label: item.def,
            value: item.id,
            oldValue: item.label,
          };
        });

      setRights(filteredRights);
    }
  }, [dictionaryDataState]);

  const query = useStaticQuery(graphql`
    query {
      submissionTextQuery: allMarkdownRemark(
        filter: { frontmatter: { title: { glob: "Step 7 text" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);

  const submissionText = query.submissionTextQuery.edges[0].node.html;

  return (
    <>
      <div className="pb-5 mb-5 border-b border-b-gray-2">
        <div
          className="markdownText p0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: submissionText,
          }}
        />
      </div>

      <InputWrapper
        title="Creative Commons Licence Selection"
        infoBoxContent={infoBoxesContent.creativeCommonsLicenceSelectionInfo}
        required
      >
        <div className="pb-2">
          Here you indicate how you would like others to use, and subsequently
          acknowledge and license, the material you are uploading. Choose the
          type of Creative Commons licence agreement you prefer. There is a
          brief explanation of each below.
          <br />
          <br />
          This licence will appear on the associated record for your material at
          DataCite. You cannot change the licence once you have pressed Submit
          at the end of the process.
        </div>
        <SelectField
          name="rights"
          isMulti={false}
          options={rights}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Feedback"
        infoBoxContent={infoBoxesContent.feedbackInfo}
        required
      >
        <div className="pb-3">
          Would you like users of your material to provide feedback?
        </div>

        <SelectField
          name="feedback"
          isMulti={false}
          options={feedback.options}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          required
        />
      </InputWrapper>

      <InputWrapper
        title="Terms and Conditions Declaration"
        infoBoxContent={infoBoxesContent.termsAndConditionsDeclarationInfo}
        required
      >
        <div className="pb-3">
          Uploading your material is subject to acceptance of the{" "}
          <a href="/terms-and-conditions" className="link" target="_blank">
            IRIS terms and conditions
          </a>
          . Please confirm that you have read and agree to the terms.
        </div>

        <SelectField
          name="licenceAgreement"
          options={licenceAgreement.options}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          isMulti={false}
          required
        />
      </InputWrapper>

      <InputWrapper
        title="Comments on the submission process"
        infoBoxContent={infoBoxesContent.commentsOnTheSubmissionProcessInfo}
      >
        <div className="pb-3">
          Lastly, and thank you for getting this far, please leave any comments
          about the submission form.
        </div>

        <TextareaField
          name="comments"
          height={100}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>
    </>
  );
}

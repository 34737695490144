import React from "react";
import InputField from "../fields/inputField";
import TextareaField from "../fields/textareaField";
import { infoBoxesContent } from "../infoBoxesContent";
import InputWrapper from "../inputWrapper";
import { PublicationFieldProps } from "../../../types/SubmissionTypes";

export default function OtherFields(props: PublicationFieldProps) {
  return (
    <>
      {/* Removed as part of recommendation https://docs.google.com/document/d/1_xMRNxVDEVbA7EUAY6sUzfYOUmo9NtkO1sqmGLISfDo/edit#bookmark=id.8wbxugknhslg
      <InputWrapper
        title="Enter a publication type"
        infoBoxContent={
          infoBoxesContent.publicationOtherEnterAPublicationTypeInfo
        }
      >
        <InputField
          name="publicationOtherEnterAPublicationType"
          referenceNumber={props.referenceNumber}
          acknowledgementNumber={props.acknowledgementNumber}
          triggerSaveMessageHandler={props.triggerSaveMessageHandler}
        />
      </InputWrapper> */}

      <InputWrapper
        title="Description"
        infoBoxContent={
          infoBoxesContent.publicationProceedingsAwardingInstitutionInfo // No text "" but why not use customPublicationType for clarity
        }
      >
        <TextareaField
          name="description"
          referenceNumber={props.referenceNumber}
          acknowledgementNumber={props.acknowledgementNumber}
          height={100}
          triggerSaveMessageHandler={props.triggerSaveMessageHandler}
        />
      </InputWrapper>
    </>
  );
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import Select from "react-dropdown-select";
import SelectField from "../fields/selectField";
import { infoBoxesContent } from "../infoBoxesContent";
import InputWrapper from "../inputWrapper";
import InputFieldsHelper, {
  getSelectedValues,
} from "../../../helpers/inputFieldsHelper";

import JournalFields from "./journal-fields";
import BookFields from "./book-fields";
import ChapterFields from "./chapter-fields";
import ProceedingsFields from "./proceedings-fields";
import ThesisFields from "./thesis-fields";
import OtherFields from "./other-fields";
import OasisFields from "./oasis-fields";
import Button from "../../htmlElements/button";
import H2 from "../../htmlElements/h2";
import RemoveIcon from "../../htmlElements/removeIcon";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

interface StepProps {
  triggerSaveMessageHandler: any;
  materialSettings: any;
}

interface PublicationTypeFormProps extends StepProps {
  referenceNumber: any;
}

function PublicationTypeForm(props: PublicationTypeFormProps) {
  const submissionState = useContext(SubmissionState) || "";

  const { triggerSaveMessageHandler, materialSettings, referenceNumber } =
    props;

  switch (
    submissionState?.publication[props.referenceNumber].publicationType.label
  ) {
    case "Journal Article":
      return (
        <JournalFields
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber}
        />
      );
    case "Book":
      return (
        <BookFields
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber}
        />
      );
    case "Chapter":
      return (
        <ChapterFields
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber}
        />
      );
    case "Proceedings":
      return (
        <ProceedingsFields
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber}
        />
      );
    case "Thesis":
      return (
        <ThesisFields
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber}
        />
      );
    case "Other":
      return (
        <OtherFields
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber}
        />
      );
    case "OASIS Link":
      return (
        <OasisFields
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber}
        />
      );
    default:
      return <></>;
  }
}

const TypeDropdown = (props: StepProps) => {
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const [fieldValues, setFieldValue] = useState({});

  const { triggerSaveMessageHandler, materialSettings } = props;

  /**
   * Check the `status` of the material - if `published` we disable the input field
   *
   * Exeption: We do not disable the field if the user is an admin user
   */
  const disabled = InputFieldsHelper.checkInputDisabledStatus({
    materialSettings,
    doiField: true,
  });

  const noteText = InputFieldsHelper.getInputDisabledNote({
    materialSettings,
    doiField: true,
    adminText:
      "This material has been published and the DOI has been created on DataCite.<br /><strong>Do not remove this publication.</strong>",
  });

  return submissionState?.publication.map(
    (reference: any, referenceNumber: number) => {
      const title = `Reference ${referenceNumber ? referenceNumber + 1 : 1}`;

      return (
        <div
          className="border border-gray-2 px-2 py-1 bg-gray-light-2  mb-5"
          key={referenceNumber}
        >
          <div className="w-full">{noteText}</div>
          <div className="grid grid-cols-2 gap-4 w-full   border-b border-gray-2 mb-5">
            <div className="text-left">
              <H2
                innerContent={title}
                // additionalClasses="border-b border-gray-2"
                additionalClasses=""
                // paddingClasses="pb-2 mb-2"
                paddingClasses="pb-2 mb-0"
              />
            </div>
            <div className="text-right">
              {disabled ? (
                ""
              ) : (
                <div
                  onClick={() => {
                    // @ts-ignore
                    submissionDispatch({
                      type: "removePublicationHandler",
                      value: referenceNumber,
                    });
                  }}
                >
                  <RemoveIcon />
                </div>
              )}
            </div>
          </div>

          <InputWrapper
            title="Publication Type"
            infoBoxContent={infoBoxesContent.publicationTypeInfo}
            required
          >
            {/* <div className="mt-2">
              <Select
                name="publicationType"
                placeholder="Please select..."
                options={[
                  {
                    label: "Journal Article",
                    value: "Journal Article",
                  },
                  {
                    label: "Book",
                    value: "Book",
                  },
                  {
                    label: "Chapter",
                    value: "Chapter",
                  },
                  {
                    label: "Proceedings",
                    value: "Proceedings",
                  },
                  {
                    label: "Thesis",
                    value: "Thesis",
                  },
                  {
                    label: "OASIS Link",
                    value: "oasis-link",
                  },
                ]}
                // styles={customStyles}
                onChange={(selectedOption) => {
                  console.log(
                    "🚀 ~ TypeDropdown ~ selectedOption:",
                    selectedOption
                  );

                  // Cancel current saveTimer when new changes are coming in before timeout passes
                  submissionDispatch({
                    type: "publicationChangeHandler",
                    value: selectedOption,
                    fieldName: "publicationType",
                    referenceNumber,
                  });

                  // Trigger save after X milliseconds to avoid too many save requests
                  // timer is canceled on new change event
                  // Update the selected values
                  setFieldValue(selectedOption);

                  triggerSaveMessageHandler();
                }}
                value={fieldValues}
              />
            </div> */}

            <SelectField
              name="publicationType"
              options={[
                {
                  label: "Journal Article",
                  value: "Journal Article",
                },
                {
                  label: "Book",
                  value: "Book",
                },
                {
                  label: "Chapter",
                  value: "Chapter",
                },
                {
                  label: "Proceedings",
                  value: "Proceedings",
                },
                {
                  label: "Thesis",
                  value: "Thesis",
                },
                // {
                //   label: "OASIS Link",
                //   value: "oasis-link",
                // },
              ]}
              referenceNumber={referenceNumber}
              triggerSaveMessageHandler={triggerSaveMessageHandler}
              isMulti={false}
              materialSettings={materialSettings}
              doiField
            />
          </InputWrapper>

          <PublicationTypeForm
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            referenceNumber={referenceNumber}
          />
        </div>
      );
    }
  );
};

export default function Step4(props: StepProps) {
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const { triggerSaveMessageHandler, materialSettings } = props;

  const query = useStaticQuery(graphql`
    query {
      submissionTextQuery: allMarkdownRemark(
        filter: { frontmatter: { title: { glob: "Step 4 text" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);

  const submissionText = query.submissionTextQuery.edges[0].node.html;

  return (
    <>
      <div className="pb-5 mb-5 border-b border-b-gray-2">
        <div
          className="markdownText p0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: submissionText,
          }}
        />
      </div>

      <TypeDropdown
        triggerSaveMessageHandler={triggerSaveMessageHandler}
        materialSettings={materialSettings}
      />

      <div className="mb-5">
        <Button
          color="blue"
          onClick={() => {
            // Save to submission
            submissionDispatch({ type: "addAnotherPublicationHandler" });
          }}
        >
          Add (another) reference
        </Button>
      </div>
    </>
  );
}

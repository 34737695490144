import React from "react";
import InputField from "../fields/inputField";
import { infoBoxesContent } from "../infoBoxesContent";
import InputWrapper from "../inputWrapper";
import { PublicationFieldProps } from "../../../types/SubmissionTypes";

export default function OasisFields(props: PublicationFieldProps) {
  return (
    <InputWrapper
      title="OASIS Summary"
      infoBoxContent={infoBoxesContent.publicationProceedingsOasisLinkInfo}
      required
    >
      <InputField
        name="oasisLink"
        referenceNumber={props.referenceNumber}
        acknowledgementNumber={props.acknowledgementNumber}
        triggerSaveMessageHandler={props.triggerSaveMessageHandler}
      />
    </InputWrapper>
  );
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { sendEventToGA } from "../../lib/functions";

import H1 from "../htmlElements/h1";
import Button from "../htmlElements/button";
import submitSteps from "../../data/submitSteps";
import { submitMaterialMetadata } from "./submitMaterialMetadata";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../context/SubmissionContext";
import { DictionaryDataState } from "../../context/DictionaryDataContext";
import { AuthenticationState } from "../../context/AuthenticationContext";
import validateSubmissionForm from "../../helpers/validateSubmittionForm";

const submissionStep = submitSteps.length - 1;
const confirmationStep = submitSteps.length;
interface RightColumnProps {
  step: number;
  materialID: string;
  title: string;
  children?: any;
  triggerSaveMessage?: boolean;
  submissionStatus?: string;
  setSubmissionStatus?: any;
  missingRequiredFields?: any;
  setMissingRequiredFields?: any;
}

function RightColumnContent(props: RightColumnProps) {
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const dictionaryDataState = useContext(DictionaryDataState) || "";
  const authenticationState = useContext(AuthenticationState) || "";

  const {
    step,
    materialID,
    title,
    children,
    triggerSaveMessage,
    submissionStatus,
    setSubmissionStatus,
    setMissingRequiredFields,
  } = props;

  const { token } = authenticationState;

  // const { instrument, participant, publication, settings } = submissionState;

  // const {
  //   creator,
  //   researchArea,
  //   typeOfFile,
  //   materialType,
  //   title: instrumentTitle,
  // } = instrument;

  // const { participantType } = participant;

  const h1 =
    (step === 0 ? "" : `Step ${step}: `) +
    (step === 0 ? "IRIS Instrument Submission" : title);

  const previousStepDefaultValue = "Previous step";
  const nextStepDefaultValue = "Next step";

  const [nextStepValue, setNextStepValue] = useState(nextStepDefaultValue); // eslint-disable-line prettier/prettier
  const [nextStepProcessingState, setNextStepProcessingState] = useState(false); // eslint-disable-line prettier/prettier
  const [nextStepProcessingDoneState, setNextStepProcessingDoneState] = useState(false); // eslint-disable-line prettier/prettier
  const [previousStepValue, setPreviousStepValue] = useState(previousStepDefaultValue); // eslint-disable-line prettier/prettier
  const [previousStepProcessingState, setPreviousStepProcessingState] = useState(false); // eslint-disable-line prettier/prettier
  const [previousStepProcessingDoneState, setPreviousStepProcessingDoneState] = useState(false); // eslint-disable-line prettier/prettier

  const showButtons = step !== confirmationStep;

  /**
   *
   */
  const resetStepButtons = () => {
    setNextStepValue(nextStepDefaultValue);
    setPreviousStepValue(previousStepDefaultValue);

    setNextStepProcessingState(false);
    setNextStepProcessingDoneState(false);

    setPreviousStepProcessingState(false);
    setPreviousStepProcessingDoneState(false);
  };

  /**
   *
   * @param goTo
   * @param direction
   */
  const saveStepHandler = (goTo: string, direction: string) => {
    if (direction === "next") {
      setNextStepValue("Saving changes..");
      setNextStepProcessingState(true);
    }

    if (direction === "previous") {
      setPreviousStepValue("Saving changes..");
      setPreviousStepProcessingState(true);
    }

    setTimeout(() => {
      if (direction === "next") {
        setNextStepProcessingState(false);
        setNextStepProcessingDoneState(true);
      }

      if (direction === "previous") {
        setPreviousStepProcessingState(false);
        setPreviousStepProcessingDoneState(true);
      }

      if (goTo) {
        setTimeout(() => {
          resetStepButtons();
          navigate(goTo);
        }, 800);
      } else {
        setTimeout(() => {
          resetStepButtons();
        }, 300);
      }
    }, 300);
  };

  /**
   * Function to control the next button step
   * @param props
   * @returns
   */
  const nextStepHandler = async () => {
    let lastAddedId = "";

    const nextStep = parseInt(step) + 1;

    const goTo = `/submit/${materialID}/${nextStep}`;

    if (parseInt(step, 10) === parseInt(submissionStep, 10)) {
      setSubmissionStatus("submitting");

      const invalidFields = await validateSubmissionForm({
        submissionState,
        allSteps: true,
      });

      console.log("🚀 ~ nextStepHandler ~ invalidFields", invalidFields);

      if (invalidFields.length > 0) {
        /**
         * Submission form in NOT valid
         */

        setSubmissionStatus("missingRequiredFields");
        setMissingRequiredFields(invalidFields);

        //
      } else {
        /**
         * Submission form is valid
         */

        // Submit material
        const response = await submitMaterialMetadata({
          submissionState,
          dictionaryDataState,
          token,
          publishThisMaterial: true,
        });

        console.log("🚀 ~ nextStepHandler ~ response", response);

        if (response?.status === 200) {
          /**
           * Empty context here!!!
           */
          submissionDispatch({
            type: "clearSubmissionState",
          });

          lastAddedId = response.data.object;

          console.log("🚀 ~ nextStepHandler ~ lastAddedId", lastAddedId);

          setSubmissionStatus("submitted");

          navigate(`${goTo}`);

          //
        } else {
          setSubmissionStatus("error");
        }
      }
    } else {
      //
      // Reset missing madatory metadata status.
      // In case it was set already, we do not want to show information on missing metadata when a user corrects them.
      // setSubmissionStatus("submitting");
      setMissingRequiredFields([]);

      saveStepHandler(goTo, "next");

      //
    }

    // return;

    // const nextStep = parseInt(step) + 1;

    // const goTo = `/submit/${materialID}/${nextStep}${lastAddedId}`;

    // if (formCheck.formValid === false) {
    //   // Form not valid
    //   setSubmissionStatus("missingRequiredFields");
    //   setMissingRequiredFields(formCheck.requiredFieldsCheck);
    // } else if (submissionStatus === "error") {
    //   // Don't go to next step when there is an error.
    // } else {
    //   sendEventToGA({
    //     event_name: "nextStepHandler",
    //     current_step: step,
    //     next_step: nextStep,
    //     go_to: goTo,
    //   });

    //   if (step === submissionStep) {
    //     if (formCheck.formValid) {
    //       saveStepHandler(goTo, "next");
    //     } else {
    //       console.log("cant go to next step as form is not valid");
    //     }
    //   } else if (nextStep === 1) {
    //     navigate(goTo);
    //   } else {
    //     saveStepHandler(goTo, "next");
    //   }
    // }
  };

  /**
   * Function to control the previous button step
   * @param props
   * @returns
   */
  const previousStepHandler = () => {
    const previousStep = parseInt(step) - 1;

    const goTo = `/submit/${materialID}/${previousStep}`;

    sendEventToGA({
      event_name: "previousStepHandler",
      current_step: step,
      previous_step: previousStep,
      go_to: goTo,
    });

    saveStepHandler(goTo, "previous");
  };

  useEffect(() => {
    if (triggerSaveMessage) {
      saveStepHandler("", "next");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSaveMessage]);

  useEffect(() => {
    setNextStepValue(nextStepDefaultValue);

    if (parseInt(step, 10) === 0) {
      setNextStepValue("Start");
    }

    if (parseInt(step, 10) === parseInt(submissionStep, 10)) {
      setNextStepValue("Submit");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <>
      <H1 innerContent={h1} additionalClasses="pb-5" />

      <div className="text-sm">
        {children}

        {showButtons ? (
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6 md:col-span-6">
              {step > 0 && step <= submissionStep ? (
                <Button
                  color="blue"
                  onClick={previousStepHandler}
                  isProcessingButton={previousStepProcessingState}
                  isProcessingDoneButton={previousStepProcessingDoneState}
                  disabled={
                    previousStepProcessingState
                      ? true
                      : !!previousStepProcessingDoneState
                  }
                  paddingClasses="pb-2 pt-1 px-3"
                >
                  <div className="inline-flex items-center align-middle">
                    <svg
                      className="fill-current w-5 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.4939 20.5644C11.1821 20.8372 10.7083 20.8056 10.4356 20.4939L3.43557 12.4939C3.18814 12.2111 3.18814 11.7889 3.43557 11.5061L10.4356 3.50613C10.7083 3.1944 11.1822 3.16281 11.4939 3.43557C11.8056 3.70834 11.8372 4.18216 11.5644 4.49388L5.65283 11.25L20 11.25C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75L5.65283 12.75L11.5644 19.5061C11.8372 19.8179 11.8056 20.2917 11.4939 20.5644Z"
                      />
                    </svg>
                    <span>{previousStepValue}</span>
                  </div>
                </Button>
              ) : (
                ""
              )}
            </div>

            <div className="col-span-6 md:col-span-6 text-right">
              {step >= 0 && step <= submissionStep ? (
                <Button
                  color="blue"
                  onClick={nextStepHandler}
                  isProcessingButton={nextStepProcessingState}
                  isProcessingDoneButton={nextStepProcessingDoneState}
                  disabled={
                    nextStepProcessingState
                      ? true
                      : !!nextStepProcessingDoneState
                  }
                  paddingClasses="pb-2 pt-1 px-3"
                >
                  <div className="inline-flex items-center align-middle">
                    <span>{nextStepValue}</span>
                    <svg
                      className="fill-current w-6 h-4 ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5061 3.43557C12.8178 3.16281 13.2917 3.19439 13.5644 3.50612L20.5644 11.5061C20.8119 11.7889 20.8119 12.2111 20.5644 12.4939L13.5644 20.4939C13.2917 20.8056 12.8178 20.8372 12.5061 20.5644C12.1944 20.2917 12.1628 19.8178 12.4356 19.5061L18.3472 12.75H4C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25H18.3472L12.4356 4.49388C12.1628 4.18215 12.1944 3.70833 12.5061 3.43557Z"
                      />
                    </svg>
                  </div>
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default RightColumnContent;

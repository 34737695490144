/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { isNumber } from "lodash";
import InputFieldsHelper, {
  getSelectedValues,
} from "../../../helpers/inputFieldsHelper";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import {
  instrumentPropertyList,
  participantsPropertyList,
  settingsPropertyList,
} from "../../../types/UIElements";

let saveTimer: null | ReturnType<typeof setTimeout> = null;

interface InputFieldProps {
  name: string;
  options: any;
  placeholder?: string;
  isMulti?: boolean;
  referenceNumber?: number;
  acknowledgementNumber?: number;
  triggerSaveMessageHandler?: any;
  zIndex?: number;
  materialSettings?: any;
  doiField?: boolean;
  customChangeHandler?: any;
  customFieldValues?: any;
}

export default function SelectField(props: InputFieldProps) {
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const {
    name,
    options,
    placeholder,
    isMulti,
    referenceNumber,
    acknowledgementNumber,
    triggerSaveMessageHandler,
    zIndex,
    materialSettings,
    doiField,
    customChangeHandler,
    customFieldValues,
  } = props;

  const [fieldValues, setFieldValue] = useState([""]);

  useEffect(() => {
    if (name !== "journalName") {
      setTimeout(() => {
        setFieldValue(
          getSelectedValues({
            name,
            referenceNumber,
            acknowledgementNumber,
            submissionState,
            customFieldValues,
          })
        );
      }, 200);
    }
  }, [
    submissionState,
    name,
    options,
    referenceNumber,
    acknowledgementNumber,
    materialSettings,
    customChangeHandler,
    customFieldValues,
  ]);

  useEffect(() => {
    if (name === "journalName") {
      setTimeout(() => {
        setFieldValue(
          getSelectedValues({
            name,
            referenceNumber,
            acknowledgementNumber,
            submissionState,
            customFieldValues,
          })
        );
      }, 300);
    }
  }, []);

  // Update field values when `customFieldValues` has been updated
  // This overwrites the previous value
  useEffect(() => {
    setFieldValue(customFieldValues);
  }, [customFieldValues]);

  // Detect which handler to use
  let onChangeHandler = "inputChangeHandler";

  if (name === "acknowledgementType") {
    onChangeHandler = "acknowledgementTypeChangeHandler";
  } else if (isNumber(referenceNumber)) {
    onChangeHandler = "publicationChangeHandler";
  } else if (isNumber(acknowledgementNumber)) {
    onChangeHandler = "acknowledgementChangeHandler";
  } else if (participantsPropertyList.includes(name)) {
    onChangeHandler = "participantChangeHandler";
  } else if (instrumentPropertyList.includes(name)) {
    onChangeHandler = "instrumentChangeHandler";
  } else if (settingsPropertyList.includes(name)) {
    onChangeHandler = "settingsChangeHandler";
  } else {
    //
  }

  /**
   * Check the `status` of the material - if `published` we disable the input field
   *
   * Exeption: We do not disable the field if the user is an admin user
   */
  const disabled = InputFieldsHelper.checkInputDisabledStatus({
    materialSettings,
    doiField: doiField || false,
  });

  const noteText = InputFieldsHelper.getInputDisabledNote({
    materialSettings,
    doiField: doiField || false,
  });

  // Add a z-index is a value is passed
  const customStyles = zIndex
    ? {
        container: (provided: any) => ({ ...provided, zIndex }),
      }
    : {
        container: (provided: any) => ({ ...provided }),
      };

  const key = "mt-2";
  const additionalMarginClass = "mt-2";

  /**
   * Filter the `options` to remove `REJECTED` options unless it's specific for this material.
   */
  const filteredOptions = options.filter((item: any) => item.status !== 'REJECTED' || item.materialId === submissionState.objectId);

  return (
    <div key={key} className={additionalMarginClass}>
      {noteText}
      <Select
        isDisabled={disabled}
        isMulti={isMulti !== false}
        isSearchable
        name={name}
        placeholder={placeholder || "Please select..."}
        options={filteredOptions}
        styles={customStyles}
        onChange={(selectedOption) => {
          if (customChangeHandler) {
            customChangeHandler(selectedOption);
          } else {
            // Cancel current saveTimer when new changes are coming in before timeout passes
            clearTimeout(saveTimer);

            submissionDispatch({
              type: onChangeHandler,
              value: selectedOption,
              fieldName: name,
              referenceNumber,
              acknowledgementNumber,
            });

            // Trigger save after X milliseconds to avoid too many save requests
            // timer is canceled on new change event
            saveTimer = setTimeout(() => {
              // Update the selected values
              setFieldValue(
                getSelectedValues({
                  name,
                  referenceNumber,
                  acknowledgementNumber,
                  submissionState,
                  customFieldValues,
                })
              );

              triggerSaveMessageHandler();
            }, 500);
          }
        }}
        value={fieldValues}
      />
    </div>
  );
}

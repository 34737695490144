/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { graphql, useStaticQuery } from "gatsby";
import SelectField from "../fields/selectField";
import { infoBoxesContent } from "../infoBoxesContent";
import InputWrapper from "../inputWrapper";
import { createDropdownList } from "./createDropdownList";
import HierarchicalSelectField from "../fields/hierarchicalSelectField";
import InputField from "../fields/inputField";
import RadioField from "../fields/radioField";
import { DictionaryDataState } from "../../../context/DictionaryDataContext";
import LoadingIcon from "../../loadingIcon";
import NewOtherForm from "../fields/newOtherForm";

interface StepProps {
  triggerSaveMessageHandler: any;
}

export default function Step3(props: StepProps) {
  const dictionaryDataState = useContext(DictionaryDataState) || [];

  const { triggerSaveMessageHandler } = props;

  const [participantTypes, setParticipantTypes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [proficiencyOfLearners, setProficiencyOfLearners] = useState([]);
  const [domainOfUse, setDomainOfUse] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isEmpty(dictionaryDataState)) {
      const { participantTypes, languages, proficiencies, domainOfUse } =
        dictionaryDataState;

      setParticipantTypes(participantTypes);
      setLanguages(languages);
      setProficiencyOfLearners(proficiencies);
      setDomainOfUse(domainOfUse);

      // Set loaded to `true` so that the content is shown to the user
      setLoaded(true);
    }
  }, [dictionaryDataState]);

  const query = useStaticQuery(graphql`
    query {
      submissionTextQuery: allMarkdownRemark(
        filter: { frontmatter: { title: { glob: "Step 3 text" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);

  const submissionText = query.submissionTextQuery.edges[0].node.html;

  return loaded ? (
    <>
      <div className="pb-5 mb-5 border-b border-b-gray-2">
        <div
          className="markdownText p0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: submissionText,
          }}
        />
      </div>

      <InputWrapper
        title="Participant type(s)"
        infoBoxContent={infoBoxesContent.participantTypeInfo}
        required
      >
        <HierarchicalSelectField
          isRequired
          options={participantTypes}
          inputName="type"
          irisMaterialSection="participant"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />

        <NewOtherForm
          title="participant type"
          inputName="participantTypeNew"
          fieldName="participantType"
          dictionaryStateName="participantTypes"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          showParent
          options={participantTypes}
        />
      </InputWrapper>

      <InputWrapper
        title="First language(s) of learners/users"
        infoBoxContent={infoBoxesContent.firstLanguageOfLearnersInfo}
      >
        <SelectField
          name="firstLanguage"
          options={languages}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Language(s) being used/learned"
        infoBoxContent={infoBoxesContent.languageBeingLearnedInfo}
      >
        <SelectField
          name="languageBeingLearned"
          options={languages}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Ages of learners/users"
        infoBoxContent={infoBoxesContent.agesOfLearners}
      >
        <InputField
          name="ageOfLearner"
          isMulti
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Gender of learners/users"
        infoBoxContent={infoBoxesContent.genderOfLearners}
      >
        <RadioField
          name="genderOfLearners"
          options={["male", "female", "non-binary", "mixed", "other"]}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Proficiency of learners/users"
        infoBoxContent={infoBoxesContent.proficiencyOfLearners}
        required
      >
        <HierarchicalSelectField
          isRequired
          options={proficiencyOfLearners}
          inputName="proficiencyOfLearners"
          irisMaterialSection="participant"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />

        <NewOtherForm
          title="proficiency of learners"
          inputName="proficiencyOfLearnersNew"
          fieldName="proficiencyOfLearners"
          dictionaryStateName="proficiencies"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          showParent
          options={proficiencyOfLearners}
        />
      </InputWrapper>

      {/* <InputWrapper
        title="Type of instruction / Treatment during intervention"
        infoBoxContent={infoBoxesContent.typeOfInstruction}
      >
        <InputField
          name="typeOfInstruction"
          isMulti
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper> */}

      {/* <InputWrapper
        title="Amount of instruction"
        infoBoxContent={infoBoxesContent.amountOfInstruction}
      >
        <InputField
          name="amountOfInstruction"
          isMulti
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper> */}

      {/* <InputWrapper
        title="Length of residence of learners"
        infoBoxContent={infoBoxesContent.lengthOfResidenceOfLearners}
      >
        <InputField
          name="lengthOfResidence"
          isMulti
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper> */}

      {/* <InputWrapper
        title="Age of learners at arrival"
        infoBoxContent={infoBoxesContent.ageOfLearnersAtArrival}
      >
        <InputField
          name="ageOfArrival"
          isMulti
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper> */}

      {/* <InputWrapper
        title="Amount of prior instruction"
        infoBoxContent={infoBoxesContent.amountOfPriorInstruction}
      >
        <InputField
          name="amountOfPriorInstruction"
          placeholder={[
            "Length of prior instruction",
            "Type of prior instruction",
          ]}
          preText={["Length of prior instruction", "Type of prior instruction"]}
          isMulti
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper> */}

      {/* <InputWrapper
        title="Time spent using the L2"
        infoBoxContent={infoBoxesContent.timeSpentUsingTheL2}
      >
        <InputField
          name="timeSpent"
          isMulti
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper> */}

      {/* <HierarchicalSelectField
        title="Domains of use of language use/learning"
        options={domainOfUse}
        inputName="domainOfUse"
        irisMaterialSection="participant"
        infoBoxContent={infoBoxesContent.domainsOfUseOfLanguageBeingLearned}
        isRequired={false}
        triggerSaveMessageHandler={triggerSaveMessageHandler}
      /> */}
    </>
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
}

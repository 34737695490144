/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
export const infoBoxesContent = {

   // Step 2: Material details
  authorInfo: "The person/people who adapted, created, or drew together the material for the puposes of research. NB. Published works that have used the material and acknowledgements to earlier versions are entered on another tab. All are searchable. Please search our list of authors in IRIS first. If your author is not there, please enter the author's name manually (see options below).",
  materialTypeInfo: "One of the aims of IRIS is to develop a system for cataloguing materials. To that end, we have provided a comprehensive list below. If you don't believe your material fits into any of the labels we have provided, please click ‘Add new label’ and type your own label. We will review all suggestions, and incorporate them (without duplicating our existing labels).",
  materialDataTypeInfo: "If you are uploading *materials* that elicit data, please indicate the type of data elicited here. If you are uploading any other type of materials (data, scripts, coding protocols, or a postprint), then you do not need to indicate the type of data - leave this field blank.",
  materialLinguisticFeatureInfo: "Type of linguistic feature.",
  materialLanguageOfInstructionsWithinMaterialInfo: "Language in which any instructions are written.",
  materialSourceOfFunding: "Funding body that supported the development of the material.",
  materialTitleInfo: "Please give a name for your deposit (i.e., a title). Some materials are known by a specific name (e.g., MLAT, AMBT). If your deposit does not have a standard title yet, please describe it in a few words.",
  materialReliabilityInfo: "Please include here any and all information related to the reliability of your instrument(s), if applicable, such as (a) actual reliability estimates and (b) the indices used (e.g., Cronbach's alpha, Kappa, % agreement).",
  materialNotesInfo: "Any other comments regarding, for example, stage of preparation, piloting, and past usage.",
  
  // Step 3: Participants
  participantTypeInfo: "Participants involved in research.",
  firstLanguageOfLearnersInfo: "First language(s) of learners/users",
  languageBeingLearnedInfo: "Language(s) being learned.",
  agesOfLearners: "Ages of learners/users. Any format is appropriate. For example, an age range, a mean, one specific age, age in years, age in months. The field accepts free text.",
  genderOfLearners: "Gender of learners/users",
  proficiencyOfLearners: "Proficiency of learners/users",
  typeOfInstruction: "Type of treatment given during experimental intervention e.g. processing instruction, recasts, oral interaction tasks",
  amountOfInstruction: "If intervention study, length and/or amount of instruction during intervention period (e.g. 9 hours and/or 2 weeks)",
  lengthOfResidenceOfLearners: "Length of residence in country where L2 used. This is a free text box. You can provide specific length in years and/or months; or you can provide a range.",
  ageOfLearnersAtArrival: "This is a free text box. You can provide specific age in years and/or months; or you can provide a range.",
  amountOfPriorInstruction: "Length of exposure to previous instruction. This is a free text box. You can provide length in years and/or months; or you can provide a range.",
  timeSpentUsingTheL2: "Time spent using the L2. This is a free text box and you can provide your information in any format, for example, as hours per day, or days per week, or weeks per year.",
  domainsOfUseOfLanguageBeingLearned: "",


  // Step 4: Publications
  publicationTypeInfo: "",
  publicationTypeAuthorInfo: "Please enter multiple authors in the order they appear on the publication. Please search our list of authors in IRIS first. If you need to manually type in an author's name again, please use exactly the same text that you used previously.",
  publicationTypeTitleOfArticleInfo: "",
  publicationTypeNameOfJournalInfo: "",
  publicationTypePublicationDateInfo: "",
  publicationTypeVolumeInfo: "",
  publicationTypeIssueInfo: "",
  publicationTypePagesInfo: "",

  // Step 5: DOI
  doiTitleInfo: "",

  // Step 6: Acknowledgements
  // publicationBookAuthorInfo: "",
  publicationBookTitleInfo: "",
  publicationBookPublicationDateInfo: "",
  publicationBookPlaceOfPublicationInfo: "",
  publicationBookPublisherInfo: "",

  publicationChapterTitleOfBookInfo: "",
  publicationChapterTitleOfChapterInfo: "",
  publicationChapterPublicationDateInfo: "",
  publicationChapterPagesInfo: "",
  publicationChapterPlaceOfPublicationInfo: "",
  publicationChapterPublisherInfo: "",

  publicationProceedingsTitleOfProceedingsInfo: "",
  publicationProceedingsPublicationDateInfo: "",
  publicationProceedingsEditorInfo: "",
  publicationProceedingsConferenceNameInfo: "",

  publicationProceedingsTitleOfThesisInfo: "",
  publicationProceedingsAwardingInstitutionInfo: "",
  publicationProceedingsDateOfAwardInfo: "",

  acknowledgementTypeInfo: "If applicable, acknowledgements of earlier versions of the material you are uploading.<br/><br/><strong>Earlier Version</strong><br/>Use this field to provide details of publications in which an earlier version of your material was used to collect data e.g. if you based your material on the work of another researcher, and would therefore normally cite their work in your publication.<br/><br/><strong>Other Sources</strong><br/>Use this field to provide details of any source you have used in the compilation of your material e.g. text book, pictures from the internet.",
  publicationOtherEnterAPublicationTypeInfo: "",
  creativeCommonsLicenceSelectionInfo: "Here is a brief explanation of the licence terms. For further information see https://creativecommons.org/licences/.<br /><br /><strong>Attribution-NonCommercial</strong><br />Others downloading your material:<br />- are free to copy, distribute and display the work<br />- are free to adapt and build upon the work<br />- may not use the work for commercial purposes<br />- must give the original author credit<br />- any derivatives (adaptations of the work) must also be non-commercial, but can be licensed on alternative terms<br /><br /><strong>Attribution-NonCommercial-ShareAlike</strong><br />Others downloading your material must adhere to the same conditions as the Attribution-NonCommercial Licence above. Also, anything they create (derivatives) must be distributed under the same type of licence ('Share Alike').",
  feedbackInfo: "",
  termsAndConditionsDeclarationInfo: "",
  commentsOnTheSubmissionProcessInfo: "This comment will only be visible to the IRIS team not IRIS users.",

  identifierInfo: "If possible, please enter a DOI in the following format, in full: https://doi.org/10.1177/1362168814558838 If the DOI is not in this format, it will prevent the upload from being saved.",
  publicationProceedingsOasisLinkInfo: 'If the publication associated with this material has an <a href="https://oasis-database.org/">OASIS summary</a>, please include the URL here.',
};

import React, { useContext, useEffect, useState } from "react";
import { concat, range, isEmpty } from "lodash";
import InputField from "../fields/inputField";
import SelectField from "../fields/selectField";
import { infoBoxesContent } from "../infoBoxesContent";
import InputWrapper from "../inputWrapper";
import { DictionaryDataState } from "../../../context/DictionaryDataContext";
import LoadingIcon from "../../loadingIcon";
import NewAuthorForm from "../fields/newAuthorForm";
import { PublicationFieldProps } from "../../../types/SubmissionTypes";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";
import NewOtherForm from "../fields/newOtherForm";

const thisYear = new Date().getFullYear();

export default function BookFields(props: PublicationFieldProps) {
  const dictionaryDataState = useContext(DictionaryDataState) || "";
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const { referenceNumber, acknowledgementNumber, triggerSaveMessageHandler, materialSettings } = props; // eslint-disable-line prettier/prettier

  const [authors, setAuthors] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isEmpty(dictionaryDataState)) {
      const { authors, publishers } = dictionaryDataState;

      setAuthors(authors);
      setPublishers(publishers);

      // Set loaded to `true` so that the content is shown to the user
      setLoaded(true);
    }
  }, [dictionaryDataState]);

  // Get the authors from step 2 and save them here
  useEffect(() => {
    if (
      referenceNumber >= 0 &&
      submissionState?.publication[referenceNumber]?.author === undefined
    ) {
      submissionDispatch({
        type: "publicationChangeHandler",
        value: submissionState.instrument.creator,
        fieldName: "author",
        referenceNumber,
      });
    }

    if (
      acknowledgementNumber >= 0 &&
      submissionState?.acknowledgement[acknowledgementNumber]?.publication
        ?.author === undefined
    ) {
      submissionDispatch({
        type: "acknowledgementChangeHandler",
        value: submissionState.instrument.creator,
        fieldName: "author",
        acknowledgementNumber,
      });
    }
  }, []);

  return loaded ? (
    <>
      <InputWrapper
        title="Author (last name, first name or initial)"
        infoBoxContent={infoBoxesContent.publicationTypeAuthorInfo}
        required
      >
        <SelectField
          name="author"
          options={authors}
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />

        <NewAuthorForm
          authors={authors}
          setAuthors={setAuthors}
          fieldName="author"
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Title of Book"
        infoBoxContent={infoBoxesContent.publicationBookTitleInfo}
        required
      >
        <InputField
          name="title"
          placeholder="Title of Book"
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          doiField
        />
      </InputWrapper>

      <InputWrapper
        title="Publication date"
        infoBoxContent={infoBoxesContent.publicationBookPublicationDateInfo}
        required
      >
        <SelectField
          name="publicationDate"
          options={concat(
            ["Please choose", "In press"],
            range(thisYear, 1949)
          ).map((item) => {
            return {
              value: item,
              label: item,
            };
          })}
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          isMulti={false}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Place of Publication"
        infoBoxContent={infoBoxesContent.publicationBookPlaceOfPublicationInfo}
      >
        <InputField
          name="placeOfPublication"
          placeholder="Place of Publication"
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Publisher"
        infoBoxContent={infoBoxesContent.publicationBookPublisherInfo}
        required
      >
        <SelectField
          name="publisher"
          options={publishers}
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />

        <NewOtherForm
          title="publisher"
          inputName="publisherNew"
          fieldName="publisher"
          dictionaryStateName="publishers"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          options={publishers}
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
        />
      </InputWrapper>

      <InputWrapper
        title="Identifier"
        infoBoxContent={infoBoxesContent.identifierInfo}
        customClasses="addAnotherIdentifierLink"
      >
        <InputField
          name="identifier"
          referenceNumber={referenceNumber}
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          placeholder="https://doi.org/"
        />
      </InputWrapper>
    </>
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
}

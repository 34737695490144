import React from "react";
import getApiData from "../lib/getApiData";

interface GetDetailsDataProps {
  materialID: string;
  token: string;
  isAuthenticated: boolean;
}

const getDetailsData = async (props: GetDetailsDataProps) => {
  const { materialID, isAuthenticated, token } = props;

  const details = await getApiData({
    endpoint: `materials/${materialID}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "X-Amz-Security-Token": isAuthenticated ? token : "",
    },
  });

  if (details && details.status === 200) {
    return details.data;
  }

  return {};
};

export default getDetailsData;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useContext, useState } from "react";
import clsx from "clsx";
import { isArray, isNumber, isEmpty } from "lodash";
import getApiData from "../../../lib/getApiData";
import InputField from "./inputField";
import Button from "../../htmlElements/button";
import InputFieldsHelper from "../../../helpers/inputFieldsHelper";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import { AuthenticationState } from "../../../context/AuthenticationContext";
import {
  instrumentPropertyList,
  participantsPropertyList,
  settingsPropertyList,
} from "../../../types/UIElements";

interface GetCurrentValuesProps {
  name: string;
  referenceNumber?: any;
  acknowledgementNumber?: any;
  submissionState: any;
}

const getCurrentValues = (props: GetCurrentValuesProps) => {
  const { name, referenceNumber, acknowledgementNumber, submissionState } =
    props;

  // eslint-disable-next-line prettier/prettier
  const { publication, acknowledgement, participant, instrument, settings } = submissionState;

  let fieldValues = [""];

  if (isNumber(referenceNumber)) {
    fieldValues = publication[referenceNumber][name]
      ? isArray(publication[referenceNumber][name]) &&
        publication[referenceNumber][name].length > 0
        ? publication[referenceNumber][name]
        : [publication[referenceNumber][name]]
      : [];
  } else if (isNumber(acknowledgementNumber)) {
    fieldValues = acknowledgement[acknowledgementNumber].publication[name]
      ? isArray(acknowledgement[acknowledgementNumber].publication[name]) &&
        acknowledgement[acknowledgementNumber].publication[name].length > 0
        ? acknowledgement[acknowledgementNumber].publication[name]
        : [acknowledgement[acknowledgementNumber].publication[name]]
      : [];
  } else if (participantsPropertyList.includes(name)) {
    fieldValues = participant[name]
      ? isArray(participant[name]) && participant[name].length > 0
        ? participant[name]
        : [participant[name]]
      : [];
  } else if (instrumentPropertyList.includes(name)) {
    fieldValues = instrument[name]
      ? isArray(instrument[name]) && instrument[name].length > 0
        ? instrument[name]
        : [instrument[name]]
      : [];
  } else if (settingsPropertyList.includes(name)) {
    fieldValues = settings[name]
      ? isArray(settings[name])
        ? settings[name]
        : [settings[name]]
      : [];
  } else {
    fieldValues = submissionState[name] ? submissionState[name] : [];
  }

  return fieldValues;
};

interface NewAuthorFormProps {
  authors: any;
  setAuthors: any;
  fieldName: string;
  triggerSaveMessageHandler: any;
  referenceNumber?: any;
  acknowledgementNumber?: any;
  authorOrEditor?: string;
  materialSettings?: any;
  doiField?: boolean;
}

export default function NewAuthorForm(props: NewAuthorFormProps) {
  const submissionState = useContext(SubmissionState) || {
    newAuthorFirstname: "",
    newAuthorLastname: "",
  };
  const submissionDispatch = useContext(SubmissionDispatch) || "";
  const authenticationState = useContext(AuthenticationState) || "";

  const {
    authors,
    setAuthors,
    fieldName: name,
    triggerSaveMessageHandler,
    referenceNumber,
    acknowledgementNumber,
    authorOrEditor,
    materialSettings,
    doiField,
  } = props;

  const { newAuthorFirstname, newAuthorLastname } = submissionState;

  const defaultButtonText = `Add new ${authorOrEditor}`;
  const loadingButtonText = "Please wait, adding author";

  // Detect which handler to use
  let onChangeHandler = "inputChangeHandler";

  if (name === "acknowledgementType") {
    onChangeHandler = "acknowledgementTypeChangeHandler";
  } else if (isNumber(referenceNumber)) {
    onChangeHandler = "publicationChangeHandler";
  } else if (isNumber(acknowledgementNumber)) {
    onChangeHandler = "acknowledgementChangeHandler";
  } else if (participantsPropertyList.includes(name)) {
    onChangeHandler = "participantChangeHandler";
  } else if (instrumentPropertyList.includes(name)) {
    onChangeHandler = "instrumentChangeHandler";
  } else if (settingsPropertyList.includes(name)) {
    onChangeHandler = "settingsChangeHandler";
  } else {
    //
  }

  /**
   * Check the `status` of the material - if `published` we disable the input field
   *
   * Exeption: We do not disable the field if the user is an admin user
   */
  const disabled = InputFieldsHelper.checkInputDisabledStatus({
    materialSettings,
    doiField: true,
  });

  const [newAuthorFirstnameClasses, setAuthorFirstnameClasses] = useState("");
  const [newAuthorLastnameClasses, setAuthorLastnameClasses] = useState("");
  const [newAuthorButtonClasses, setAuthorButtonClasses] = useState("");
  const [newAuthorButtonText, setAuthorButtonText] = useState(defaultButtonText); // eslint-disable-line prettier/prettier
  const [newAuthorButtonDisabledState, setAuthorButtonDisabledState] = useState(false); // eslint-disable-line prettier/prettier

  const addNewAuthorHandler = async () => {
    // Reset missing field classes
    setAuthorFirstnameClasses("");
    setAuthorLastnameClasses("");

    setAuthorButtonDisabledState(true);
    setAuthorButtonText(loadingButtonText);

    const currentValues = getCurrentValues({
      name,
      referenceNumber,
      acknowledgementNumber,
      submissionState,
    });

    if (isEmpty(newAuthorFirstname) && isEmpty(newAuthorLastname)) {
      // Missing fistname and lastname

      setAuthorFirstnameClasses("missingName");
      setAuthorLastnameClasses("missingName");
    } else if (!isEmpty(newAuthorFirstname) && isEmpty(newAuthorLastname)) {
      // Missing lastname

      setAuthorLastnameClasses("missingName");
    } else if (!isEmpty(newAuthorLastname) && isEmpty(newAuthorFirstname)) {
      // Missing firstname

      setAuthorFirstnameClasses("missingName");
    } else {
      const newAuthorName = `${newAuthorLastname}, ${newAuthorFirstname}`;

      /**
       * Check if the new author is already in the author list.
       */
      const newAuthorCheck = authors.filter(
        (item) => item?.label?.toLowerCase() === newAuthorName?.toLowerCase()
      );

      //
      //
      //
      //
      // What to do? Message? Or just add the author.
      //
      //
      //
      //

      /**
       * If the new author does not exist, add it
       */
      if (newAuthorCheck.length < 1) {
        // Add new author to DD - through API endpoint
        const newAuthor = await getApiData({
          endpoint: "dd/author",
          method: "post",
          params: {
            item: {
              author: newAuthorName,
            },
          },
          headers: {
            "X-Amz-Security-Token": authenticationState.token,
          },
        });

        // Create the new author option
        const newOption = {
          id: newAuthor?.data?.newItemID || newAuthorName,
          label: newAuthorName,
          value: newAuthorName,
        };

        // Push the new author to the dropdown menu list.
        authors.push(newOption);
        setAuthors(authors);

        // Combine the existing selected options (currentValues) and add the new option.
        const selectedOptions = currentValues.concat(newOption);

        // Push the new author to the state so it's selected in the dropdown menu
        submissionDispatch({
          type: onChangeHandler,
          value: selectedOptions,
          fieldName: name,
          referenceNumber,
          acknowledgementNumber,
        });

        // Clear the form for adding a new author
        submissionDispatch({
          type: "inputChangeHandler",
          value: "",
          fieldName: "newAuthorFirstname",
        });

        // Clear the form for adding a new author
        submissionDispatch({
          type: "inputChangeHandler",
          value: "",
          fieldName: "newAuthorLastname",
        });
      } else {
        const checkIfAlreadyAdded = currentValues.filter(
          (item) => item?.label === newAuthorCheck[0].label
        );

        if (checkIfAlreadyAdded.length === 0) {
          // Combine the existing selected options (currentValues) and add the new option.
          const selectedOptions = currentValues.concat(newAuthorCheck[0]);

          // Push the new author to the state so it's selected in the dropdown menu
          submissionDispatch({
            type: onChangeHandler,
            value: selectedOptions,
            fieldName: name,
            referenceNumber,
            acknowledgementNumber,
          });
        }

        // Clear the form for adding a new author
        submissionDispatch({
          type: "inputChangeHandler",
          value: "",
          fieldName: "newAuthorFirstname",
        });

        // Clear the form for adding a new author
        submissionDispatch({
          type: "inputChangeHandler",
          value: "",
          fieldName: "newAuthorLastname",
        });
      }

      setTimeout(() => {
        triggerSaveMessageHandler();
      }, 500);
    }

    // Reset the form again
    setAuthorButtonDisabledState(false);
    setAuthorButtonText(defaultButtonText);
  };

  return disabled ? (
    ""
  ) : (
    <>
      <div className="p-2">Or add a new {authorOrEditor}:</div>
      <div className="grid grid-cols-8 gap-2">
        <div className="col-span-3">
          <InputField
            name="newAuthorFirstname"
            placeholder="Firstname"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            additionalClasses={clsx("inline w-full", newAuthorFirstnameClasses)}
            disabled={newAuthorButtonDisabledState}
          />
        </div>
        <div className="col-span-3">
          <InputField
            name="newAuthorLastname"
            placeholder="Lastname"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            additionalClasses={clsx("inline w-full", newAuthorLastnameClasses)}
            disabled={newAuthorButtonDisabledState}
          />
        </div>
        <div className="col-span-2 text-right">
          <Button
            color="blue"
            textSize="xs"
            onClick={addNewAuthorHandler}
            additionalClasses={clsx(
              "newAuthorButton w-full",
              newAuthorButtonClasses
            )}
            disabled={newAuthorButtonDisabledState}
          >
            {newAuthorButtonText}
          </Button>
        </div>
      </div>
    </>
  );
}

NewAuthorForm.defaultProps = {
  authorOrEditor: "author",
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect } from "react";
import Uppy from "@uppy/core";
import DropTarget from "@uppy/drop-target";
// import GoldenRetriever from "@uppy/golden-retriever";
import Compressor from "@uppy/compressor";
import AwsS3 from "@uppy/aws-s3";
import { Dashboard } from "@uppy/react";
import { graphql, useStaticQuery } from "gatsby";
import getApiData from "../../../lib/getApiData";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import { AuthenticationState } from "../../../context/AuthenticationContext";

import UploadedFiles from "../UploadedFiles";
// import { submitMaterialMetadata } from "../submitMaterialMetadata";
// import EmbargoDateField from "../fields/embargoDateField";
// import InputWrapper from "../inputWrapper";

const getPreSignedUrl = async (
  acceptedFile: any,
  objectId: string,
  token: string
) => {
  const params = {
    endpoint: "uploadurl",
    method: "post",
    params: {
      path: acceptedFile.path,
      name: acceptedFile.name,
      size: acceptedFile.size,
      fileExtension: acceptedFile.name.split(".").pop(),
      mime: acceptedFile.type,
      objectId,
    },
    headers: {
      "X-Amz-Security-Token": token,
    },
  };

  return getApiData(params);
};

interface StepProps {
  materialSettings: Object;
  triggerSaveMessageHandler: any;
}

export default function Step1(props: StepProps) {
  const submissionState = useContext(SubmissionState) || {
    objectId: "",
  };
  const submissionDispatch = useContext(SubmissionDispatch) || "";
  const authenticationState = useContext(AuthenticationState) || {
    token: "",
    isAuthenticated: false,
  };

  const { materialSettings, triggerSaveMessageHandler } = props;

  const { token } = authenticationState;

  const { objectId } = submissionState;

  const checkFileExists = (file: any, obj: any) => {
    let check = false;

    if (obj && obj.length > 0) {
      obj.map((item: any) => {
        if (item.id === file.id) {
          check = true;
        }
      });
    }

    return check;
  };

  const uppy = React.useMemo(() => {
    return (
      new Uppy({
        debug: true,
        autoProceed: true,
        closeAfterFinish: true,
        restrictions: {
          maxNumberOfFiles: 10,
        },
      })
        .use(DropTarget, { target: document.body })
        // .use(GoldenRetriever, { serviceWorker: true })
        .use(Compressor)
        .use(AwsS3, {
          async getUploadParameters(currentFile) {
            // console.log("🚀 ~ getUploadParameters ~ currentFile", currentFile);
            const signedUrl = await getPreSignedUrl(
              currentFile,
              objectId,
              token
            );
            return {
              url: signedUrl.data,
              method: "PUT",
              fields: {},
              headers: {
                "Content-Type": currentFile.type,
              },
            };
          },
        })
        .on("complete", async (result) => {
          /**
           * 1 - Save each uploaded file to the submission state
           */
          for (let i = 0; i < result.successful.length; i += 1) {
            const uploadedFile = result.successful[i];
            submissionDispatch({
              type: "addObject",
              value: {
                id: uploadedFile.id,
                extension: uploadedFile.extension,
                originalFileName: uploadedFile.name,
                createdAt: new Date().toISOString(),
                downloadFilename: uploadedFile.name,
                mime: uploadedFile.type,
                label: "INSTRUMENT",
                url: uploadedFile.uploadURL.replaceAll(
                  /((http?s):\/\/uk-ac-york-dti-iris-uploads-bucket-(.*).s3.eu-west-1.amazonaws.com\/)/gi,
                  ""
                ),
                status: "public",
                size: uploadedFile.size,
                order: i + 1,
              },
            });
          }

          /**
           * 3 - Submit material when state is changed
           */
          setTimeout(async () => {
            triggerSaveMessageHandler();
          }, 500);
        })
        .on("error", (error) => {
          console.error(error.stack);
        })
    );
  }, []);

  useEffect(() => {
    const { objects } = submissionState;

    if (objects && objects.length > 0) {
      uppy.on("file-added", (file) => {
        if (checkFileExists(file, objects)) {
          uppy.setFileState(file.id, {
            progress: {
              uploadComplete: true,
              uploadStarted: true,
            },
          });
        }
      });
    }
  }, [submissionState]);

  // const embargoDateInput = submissionState?.objectMetadata?.useEmbargoDate ? (
  //   <div style={{ paddingTop: 10 }}>
  //     Please select an embargo date: <EmbargoDateField />
  //   </div>
  // ) : (
  //   ""
  // );

  const query = useStaticQuery(graphql`
    query {
      submissionTextQuery: allMarkdownRemark(
        filter: { frontmatter: { title: { glob: "Step 1 text" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);

  const submissionText = query.submissionTextQuery.edges[0].node.html;

  return (
    <>
      <div className="pb-5 mb-5 border-b border-b-gray-2">
        <div
          className="markdownText p0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: submissionText,
          }}
        />
      </div>
      <UploadedFiles
        uppy={uppy}
        token={token}
        materialSettings={materialSettings}
      />

      {materialSettings.canAddFiles ? (
        <Dashboard
          uppy={uppy}
          showProgressDetails
          hideProgressAfterFinish
          height={400}
          className="mb-5"
        />
      ) : (
        ""
      )}

      {/* <InputWrapper
        title="Use embargo date"
        infoBoxContent="Add some information about the embargo date"
      >
        Tick this checkbox to use an embargo date:{" "}
        <input
          type="checkbox"
          name="useEmbargoDate"
          value="1"
          checked={submissionState?.objectMetadata?.useEmbargoDate || false}
          onChange={() => {
            submissionDispatch({
              type: "UPDATE_STATE",
              objectMetadata: {
                ...submissionState.objectMetadata,
                useEmbargoDate: !submissionState.objectMetadata.useEmbargoDate,
              },

              // value: !submissionState?.useEmbargoDate || false,
              // fieldName: "useEmbargoDate",
            });
          }}
        />
        {embargoDateInput}
      </InputWrapper> */}
    </>
  );
}
